/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $(document).ready(function() {
        // JavaScript to be fired on all pages
          if (typeof Modernizr !== 'undefined') {
            if (!Modernizr.flexbox && !Modernizr.flexwrap) {
              var container = document.body;
              flexibility(container);
              var onresizeTimeout;
              window.onresize = onresize;
              function onresize() {
                window.onresize = null;
                if (!onresizeTimeout) {
                  onresizeTimeout = setTimeout(function () {
                    onresizeTimeout = null;
                    flexibility(container);
                    window.onresize = onresize;
                  }, 1000 / 60);
                }
              }
            }
          }
          $('.mobile-menu-trigger').find('.trigger').on('click', function(e){
            e.preventDefault();
            $(this).toggleClass('is-triggered');
            var mobileMenu = $('.mobile-menu');
            $('.mobile-menu-icon').toggleClass('is-clicked');
            if( mobileMenu.hasClass('is-visible') ) {
              mobileMenu.removeClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
                $('body').removeClass('overflow-hidden');
              });
            } else {
              mobileMenu.addClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
                $('body').addClass('overflow-hidden');
              });
            }
          });
          $.each([$('.homepage-slider'), $('.company-slider'), $('.project-slider')], function () {
            var slider = $(this);
            sliderSpeed = 10000;
            if (slider.selector == '.homepage-slider') {
              sliderSpeed = homepageSliderSpeed;
            } else if (slider.selector == '.company-slider') {
              sliderSpeed = companySliderSpeed;
            } else {
              sliderSpeed = projectSliderSpeed;
            }
            $(slider).owlCarousel({
              center: true,
              items: 1,
              loop: true,
              autoplay: true,
              autoplayTimeout: sliderSpeed,
              autoplayHoverPause: true,
              animateOut: 'slideOutLeft',
              animateIn: 'slideInRight',
              dots: true,
              nav: true,
              navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>']
            });
          });
          $('.wpcf7').on('submit', function(e) {
            setTimeout(function() {
              $('.wpcf7-mail-sent-ok, .wpcf7-mail-sent-ng').hide();
            }, 15000);
          });
        });
      },
      finalize: function() {
        $(window).load(function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        $(document).ready(function() {
          // JavaScript to be fired on the home page
          function sliderAdjust() {
            var documentWidth = $(window).outerWidth();
            var sliderHeight = $('.homepage-slider').height();
            var headerHeight = $('.main-header').height();
            var marginTop =  sliderHeight - headerHeight + 'px';
            var captionBottom = sliderHeight * 0.2 + '%';
            $('.main-header').css('margin-bottom', marginTop);
            $('.homepage-slider').find('caption').css('bottom', captionBottom);
          }
          window.sliderAdjust = sliderAdjust;
          sliderAdjust();
          var resizeTimer;
          $(window).on('resize', function(e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function() {
              sliderAdjust();
            }, 50);
          });
        });
      },
      finalize: function() {
        $(window).load(function() {
          // JavaScript to be fired on the home page, after the init JS
          sliderAdjust();
        });
        var owl = $('.homepage-slider');
        owl.on('resized.owl.carousel', function(event) {
          sliderAdjust();
        })
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
